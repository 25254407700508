import { ApolloClient, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { auth } from "./auth/firebase";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    console.log(networkError);
  }
});

const websocketLink = new WebSocketLink({
  uri: "wss://hasura.plas.app/v1/graphql",
  options: {
    reconnect: true,
    connectionParams: async () => {
      const token = await auth.currentUser?.getIdToken();
      return {
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    },
    lazy: true,
  },
});

export const client = new ApolloClient({
  link: errorLink.concat(websocketLink),
  cache: new InMemoryCache(),
});
