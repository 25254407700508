// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithRedirect } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD90f9exDayoRk0MZd1LYSKqWnuYVuj6nA",
  authDomain: "plasapp.firebaseapp.com",
  projectId: "plasapp",
  storageBucket: "plasapp.appspot.com",
  messagingSenderId: "237246303058",
  appId: "1:237246303058:web:2559a62a272a6e613ec88b",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export async function handleSignIn() {
  for (let i = 0; i < 500; ++i) {
    await new Promise((resolve) => setTimeout(resolve, 10));
    const user = auth.currentUser;
    if (user) {
      console.log(user);
      return user;
    }
  }

  console.log("No user found in 5 second");

  await signInWithRedirect(auth, provider);
}
