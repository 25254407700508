import { useEffect } from "react";
import { handleSignIn } from "./firebase";
import React from "react";
import { User } from "firebase/auth";

export const userContext = React.createContext<{
  user: User | null;
}>({
  user: null,
});

export const useUser = () => {
  const { user } = React.useContext(userContext);
  return user;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = React.useState<User | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const user = await handleSignIn();
        if (user) {
          setUser(user);
        }
      } catch (err: any) {
        if (typeof err === "string") {
          console.log(err);
        }
      }
    })();
  }, []);

  return (
    <userContext.Provider value={{ user }}>{children}</userContext.Provider>
  );
};
