import { useUser } from "./AuthProvider";

export const AuthGuard: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const user = useUser();
  if (!user) {
    return <div>Loading...</div>;
  }
  return <>{children}</>;
};
