import React from "react";
import { AuthProvider } from "./auth/AuthProvider";
import { Welcome } from "./Welcome";
import { AuthGuard } from "./auth/AuthGuard";
import { ApolloProvider } from "@apollo/client";
import { client } from "./ApolloClient";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Import } from "./Import";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Welcome />,
  },
  {
    path: "/import",
    element: <Import />,
  },
]);

function App() {
  return (
    <AuthProvider>
      <AuthGuard>
        <ApolloProvider client={client}>
          <RouterProvider router={router} />
        </ApolloProvider>
      </AuthGuard>
    </AuthProvider>
  );
}

export default App;
