import React from "react";
import { useUser } from "./auth/AuthProvider";
import { useAllTransactionsQuery } from "./graphql";
import { Table } from "antd";

export const Welcome: React.FC = () => {
  const user = useUser();
  const { data } = useAllTransactionsQuery();
  const allTransactions = data?.transaction;

  const formatDate = (date: any) => {
    const d = new Date(date);
    return new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZoneName: "short",
    }).format(d);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text: any) => formatDate(text),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Currency",
      dataIndex: ["account", "currency"],
      key: "currency",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Holder",
      dataIndex: ["account", "holder"],
      key: "holder",
    },
    {
      title: "Bank",
      dataIndex: ["account", "bank"],
      key: "bank",
    },
  ];

  return (
    <div>
      <div>Welcome to PlasApp, {user?.displayName}!</div>
      <Table
        columns={columns}
        dataSource={allTransactions}
        rowKey={(record) => `${record.date}-${record.title}`}
        size="small"
        pagination={false}
        bordered
      />
    </div>
  );
};
