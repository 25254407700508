import { parseRevolutCSV } from "../util/parse_csv";
import { importRevolut } from "./import_revolut";

export interface ImportedTransaction {
  account_id: number;
  type: string;
  date: Date;
  title: string;
  amount: number;
}

export interface Account {
  id: number;
  currency: string;
  holder: string;
  name: string;
  bank: string;
}

export interface ImportResult {
  transactions: ImportedTransaction[];
  unknownRows: string[][]; // Rows that could not be interpreted
}

export async function importBankStatement(
  file: File,
  accounts: Account[],
  importAccountType: string,
  importAccountHolder: string
): Promise<ImportResult> {
  const userAccounts = accounts.filter(
    (account) => account.holder === importAccountHolder
  );

  if (importAccountType === "Revolut") {
    return importRevolut(file, userAccounts);
  }

  console.error("Unknown bank type", importAccountType);

  return {
    transactions: [],
    unknownRows: [],
  };
}
