import React, { useState } from "react";
import { Upload, Button, Table, Form, Select, message, Divider } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useAllAccountsQuery, useInsertTransactionsMutation } from "./graphql";
import { ImportResult, importBankStatement } from "./import/statement_import";

import styled from "styled-components";

const { Option } = Select;

const banks = ["Revolut", "Pekao", "Inteligo", "Santander", "ING"];
const holders = ["Filip", "Klau"];

const Wrapper = styled.div`
  padding: 20px;
  max-width: 1200px;
`;

export const Import: React.FC = () => {
  const accounts = useAllAccountsQuery();
  const [form] = Form.useForm();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [importResult, setImportResult] = useState<ImportResult | null>(null);

  const handleFileUpload = async (file: File) => {
    if ((accounts.data?.account ?? []).length > 0) {
      const result = await importBankStatement(
        file,
        accounts.data?.account || [],
        form.getFieldValue("bank"),
        form.getFieldValue("holder")
      );
      setImportResult(result);
      setFileUploaded(true);
      message.success(`${file.name} file uploaded successfully`);
    }
    return false; // prevent auto upload
  };

  const uploadProps = {
    beforeUpload: handleFileUpload,
  };

  const [insertTransactionsMutation, { data: mutationResult }] =
    useInsertTransactionsMutation();

  const insertTransactions = async () => {
    if (importResult) {
      await insertTransactionsMutation({
        variables: {
          transactions: importResult.transactions.map((t) => ({
            account_id: t.account_id,
            amount: t.amount,
            date: t.date.toISOString(),
            title: t.title,
            type: t.type,
          })),
        },
      });
      message.success("Transactions inserted successfully");
    }
  };

  const clearAll = () => {
    setImportResult(null);
    setFileUploaded(false);
    form.resetFields();
  };

  return (
    <Wrapper>
      <Form form={form} layout="vertical">
        <Form.Item label="Select Bank" name="bank" rules={[{ required: true }]}>
          <Select placeholder="Select a bank" disabled={fileUploaded}>
            {banks.map((bank) => (
              <Option key={bank} value={bank}>
                {bank}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Select Holder"
          name="holder"
          rules={[{ required: true }]}
        >
          <Select placeholder="Select a holder" disabled={fileUploaded}>
            {holders.map((holder) => (
              <Option key={holder} value={holder}>
                {holder}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Upload CSV">
          <Upload
            {...uploadProps}
            accept=".csv"
            showUploadList={false}
            disabled={fileUploaded}
          >
            <Button icon={<UploadOutlined />} disabled={fileUploaded}>
              {fileUploaded ? "File Uploaded" : "Click to Upload"}
            </Button>
          </Upload>
        </Form.Item>
      </Form>

      {importResult && (
        <>
          <div>
            <h3>Import Summary</h3>
            <p>Rows properly loaded: {importResult.transactions.length}</p>
            <p>Unknown rows: {importResult.unknownRows.length}</p>
            <Table
              dataSource={importResult.unknownRows.map((row, index) => ({
                key: index,
                row,
              }))}
              columns={[
                { title: "Unknown Rows", dataIndex: "row", key: "row" },
              ]}
              pagination={false}
            />
          </div>
          <Divider />
          <Button
            type="primary"
            onClick={() => {
              insertTransactions();
              clearAll();
            }}
          >
            Insert Transactions
          </Button>
          <Button onClick={clearAll}>Clear</Button>
        </>
      )}
    </Wrapper>
  );
};

export default Import;
