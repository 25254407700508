import { parseRevolutCSV } from "../util/parse_csv";
import { Account, ImportResult } from "./statement_import";

export async function importRevolut(
  file: File,
  accounts: Account[]
): Promise<ImportResult> {
  let rows = await parseRevolutCSV(file);

  // sort rows by "Started Date"
  rows = rows.sort((a, b) => {
    return (
      new Date(a["Started Date"]).getTime() -
      new Date(b["Started Date"]).getTime()
    );
  });

  const res: ImportResult = {
    transactions: [],
    unknownRows: [],
  };

  rows.forEach((row) => {
    if (row.Type === "CASHBACK" && row.Description === "Plan Cashback") {
      const acc = accounts.find(
        (a) => a.currency === row.Currency && a.bank === "Revolut Cashback"
      );
      if (acc) {
        res.transactions.push({
          account_id: acc.id,
          type: "CASHBACK",
          date: new Date(row["Started Date"]),
          title: row.Description,
          amount: parseFloat(row.Amount),
        });
      } else {
        console.log("Could not find account for row ", row);

        // add to unknown rows
        res.unknownRows.push(Object.values(row));
      }
      return;
    }

    const acc = accounts.find(
      (a) => a.currency === row.Currency && a.bank === "Revolut"
    );
    if (acc) {
      res.transactions.push({
        account_id: acc.id,
        type: row.Type,
        date: new Date(row["Started Date"]),
        title: row.Description,
        amount: parseFloat(row.Amount),
      });

      if (parseFloat(row.Fee) !== 0) {
        res.transactions.push({
          account_id: acc.id,
          type: "FEE",
          date: new Date(row["Started Date"]),
          title: "Fee: " + row.Description,
          amount: parseFloat(row.Fee),
        });
      }

      return;
    } else {
      console.log("Could not find account for row ", row);
      // add to unknown rows
      res.unknownRows.push(Object.values(row));
      return;
    }
  });

  // console.log("Imported Revolut transactions: ", res.transactions);

  return res;
}
